/* WALLPAPER FOR ALL PAGES SLUT*/
.body-class {
  background-color: rgb(42, 52, 117);
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Secular One", sans-serif;
  height: 100vh;
}

.hide {
  display: none;
}

.front-text {
  display: flex;
  justify-content: center;
  /* FIRST HEADER */
  /* SECONED HEADER */
  /* THIRD HEADER */
}
.front-text h2 {
  width: 80%;
  font-size: 3vh;
  text-align: center;
  color: rgb(127, 183, 127);
  text-align: center;
  border-radius: 10px;
}
.front-text h3 {
  width: 100%;
  font-size: 50px;
  color: rgb(127, 183, 127);
  text-align: center;
  border-radius: 10px;
}
.front-text p {
  width: 80%;
  font-size: 20px;
  color: rgb(127, 183, 127);
  margin-top: 10px;
  border-radius: 10px;
  text-align: center;
}

/* ICONS */
.social-media {
  width: 100%;
  justify-content: center;
  padding-left: 50px;
  display: flex;
  gap: 50px;
}
.social-media .social-icons {
  width: 40px;
  height: 40px;
}
.social-media .social-icons:hover {
  transition: 0.5s;
  color: rgb(127, 183, 127);
}

/* SVG Shape */
.custom-shape-divider-top-1658751484 {
  position: relative;
  padding: 0 !important;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}
.custom-shape-divider-top-1658751484 .shape-fill {
  padding: 0;
  fill: rgb(127, 183, 127);
}
.custom-shape-divider-top-1658751484 svg {
  display: block;
  width: calc(131% + 1.3px);
  height: 180px;
}

.front-side {
  display: flex;
  justify-content: center;
  min-height: 100%;
  font-family: "Secular One", sans-serif;
  border-radius: 10px;
}

@media screen and (max-width: 991px) {
  .front-side {
    margin-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  .intro-text-container h3 {
    font-size: 40px;
  }
  .intro-text-container p {
    font-size: 20px;
  }
  .social-media {
    gap: 30px;
  }
  .social-media .social-icons {
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width: 640px) {
  .intro-text-container h3 {
    font-size: 30px;
  }
  .intro-text-container p {
    font-size: 13px;
  }
}
@media screen and (max-width: 480px) {
  .intro-text-container p {
    font-size: 10px;
  }
}/*# sourceMappingURL=Home.css.map */