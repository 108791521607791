$base-green: rgb(21, 141, 71);
$background: rgb(42, 52, 117);

// $cloud: rgb(98, 152, 124);
$cloud: rgb(127, 183, 127);
$midnightBlue: #2a6574;
$black: #212529;

/* WALLPAPER FOR ALL PAGES SLUT*/
.body-class {
  background-color: $background;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Secular One", sans-serif;
  height: 100vh;
  // min-height: 500px;
}

.hide {
  display: none;
}

.front-text {
  display: flex;
  justify-content: center;
  /* FIRST HEADER */
  h2 {
    width: 80%;
    font-size: 3vh;
    text-align: center;
    color: $cloud;
    text-align: center;
    border-radius: 10px;
  }
  /* SECONED HEADER */
  h3 {
    width: 100%;
    font-size: 50px;
    color: $cloud;
    text-align: center;
    border-radius: 10px;
  }

  /* THIRD HEADER */
  p {
    width: 80%;
    font-size: 20px;
    color: $cloud;
    margin-top: 10px;
    border-radius: 10px;
    text-align: center;
  }
}

/* ICONS */
.social-media {
  width: 100%;
  justify-content: center;
  padding-left: 50px;
  display: flex;
  gap: 50px;
  // font-size: 30px;

  .social-icons {
    width: 40px;
    height: 40px;
  }

  .social-icons:hover {
    transition: 0.5s;
    color: $cloud;
  }
}

/* SVG Shape */
.custom-shape-divider-top-1658751484 {
  position: relative;
  padding: 0 !important;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;

  .shape-fill {
    padding: 0;
    fill: $cloud;
  }

  svg {
    display: block;
    width: calc(131% + 1.3px);
    height: 180px;
  }
}

// FRONT-SIDE

.front-side {
  display: flex;
  justify-content: center;
  min-height: 100%;
  font-family: "Secular One", sans-serif;
  border-radius: 10px;
}
@media screen and (max-width: 991px) {
  .front-side {
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .intro-text-container {
    h3 {
      font-size: 40px;
    }
    p {
      font-size: 20px;
    }
  }
 .social-media {
    gap: 30px;
    .social-icons {
      width: 30px;
      height: 30px;
    }
  }
}
@media screen and (max-width: 640px) {
  .intro-text-container {
    h3 {
      font-size: 30px;
    }
    p {
      font-size: 13px;
    }
  }
}
@media screen and (max-width: 480px) {
  .intro-text-container {
    p {
      font-size: 10px;
    }
  }
}
