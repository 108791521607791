.category {
  /* Make dropdown visible on hover */
}
.category .menu {
  background-color: transparent;
  border: none;
  display: block;
  position: relative;
}
.category .menu .menu-title {
  display: block;
  height: 40px;
  text-wrap: wrap;
  padding: 12px 0 0;
  cursor: pointer;
  text-align: center;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  transition: 0.3s background-color;
}
.category .menu .menu-title:hover {
  background: rgb(98, 152, 124);
  border-radius: 2px;
  color: #212529;
}
.category .menu .menu-dropdown {
  min-width: 100%;
  padding: 15px 0;
  position: absolute;
  z-index: 100;
  visibility: hidden; /* Initially hidden */
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.category .menu .menu-dropdown .item {
  background-color: #212529;
  padding: 2px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 17px;
  color: rgb(98, 152, 124);
}
.category .menu .menu-dropdown > * {
  cursor: default;
  overflow: hidden;
  height: 30px;
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0);
  white-space: nowrap;
  transition: 0.5s height cubic-bezier(0.73, 0.32, 0.34, 1.5), 0.5s padding cubic-bezier(0.73, 0.32, 0.34, 1.5), 0.5s margin cubic-bezier(0.73, 0.32, 0.34, 1.5), 0.5s 0.2s color, 0.2s background-color;
}
.category .menu:hover > .menu-dropdown {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.category .menu:not(:hover) > .menu-dropdown > * {
  visibility: hidden;
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  color: rgba(25, 25, 25, 0);
  transition: 0.5s 0.1s height, 0.5s 0.1s padding, 0.5s 0.1s margin, 0.3s color, 0.6s visibility;
  z-index: 99;
}
@media screen and (max-width: 480) {
  .category .item {
    font-size: 13px;
  }
  .category h4 {
    font-size: 15px;
  }
}
.category .line {
  width: 100%;
  height: 3px;
  background-color: #2a6574;
}

@media screen and (max-width: 1460px) {
  .category-child .menu-title {
    font-size: 20px;
  }
}
@media screen and (max-width: 1330px) {
  .category-child .menu-title {
    font-size: 16px;
  }
}
@media screen and (max-width: 640px) {
  .category-child .menu-title {
    font-size: 15px;
  }
}
@media screen and (max-width: 480px) {
  .category-child .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .category-child .menu .menu-title {
    width: -moz-fit-content;
    width: fit-content;
    font-size: 0.7em;
  }
}/*# sourceMappingURL=Skills.css.map */