
$base-green: rgb(21, 141, 71);
$background: rgb(42, 52, 117);
$background-dark: #28285a;
// $cloud: rgb(78, 156, 115);
$cloud: rgb(127, 183, 127);
$line: #2a6574;
$black: #212529;
.line-smal {
  width: 10%;
  height: 3px;
  background-color: $line;
}
.body-class-dark {
  height: 100%;
  background-color: $background-dark;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  min-height: 100vh;
  font-family: "Secular One", sans-serif;
  height: 100%;
}

.container-mywork {
  display: flex;
  flex-direction: column;
  gap: 100px;
  margin: 0;
  padding-top: 200px;
}

.header-mywork {
  h1 {
    color: $cloud;
  }
  p {
    color: $cloud;
    font-size: 20px;
  }
}

.content-mywork {
  border-radius: 10px;
  background-color: rgba(136, 181, 152, 0.129);
  padding-bottom: 50px;
  h1 {
    color: rgb(125, 157, 142);
  }
  h2 {
    color: rgb(82, 155, 121);
  }
  .project-desc {
    padding-top: 60px;
    padding-left: 60px;
    
    p {
      color: rgb(127, 183, 127);
      font-size: 20px;
    }
    
    
  }
  .dev-list{
      .item{
        background-color: $black;
        border-radius: 5px;
        font-size: 17px;
        color: $cloud;
        max-width: fit-content;
    }
    }
}
.content-work-img {
  display: flex;
  justify-content: center;
  img {
    filter: grayscale(20%) brightness(75%);
    border-radius: 10px;
    // max-width: 100%;
    height: 30vh;

    cursor: zoom-in;
    padding: 0;
    margin-top: 20px;
  }

  @media screen and (max-width: 415px) {
  img {
    filter: grayscale(20%) brightness(75%);
    border-radius: 10px;
    max-width: 100%;
    height: 20vh;

    cursor: zoom-in;
    padding: 0;
    margin-top: 20px;
  }
}
  @media screen and (max-width: 280px) {
  img {
    filter: grayscale(20%) brightness(75%);
    border-radius: 10px;
    max-width: 100%;
    height: 15vh;

    cursor: zoom-in;
    padding: 0;
    margin-top: 20px;
  }
}
}

// .content-mywork-mini {
//   border-radius: 10px;
//   border: 1px solid;
//   background-color: rgba(136, 181, 152, 0.129);
// }

// .content-cv {
//   text-align: center;
//   height: 60vh;
// }
// .content-header-cv {
//   color: rgb(125, 157, 142);
// }
// .content-cv iframe {
//   padding: 20px;
//   filter: grayscale(20%) brightness(75%);
//   width: 80%;
//   height: 90%;
// }

.repo {
  font-family: "Staatliches", cursive;
  padding: 20px;
  border-radius: 10px;
}
.repo input {
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
}
.repo h1 {
  color: rgb(125, 157, 142);
}
.repo li {
  margin-top: 10px;
  padding-top: 10px;
  color: rgb(125, 157, 142);
  list-style: none;
}

.repo li a {
  padding-top: 10px;
  color: rgb(125, 157, 142);
  text-decoration: none;
  font-size: 20px;
}

.repo button {
  background-color: white;
  color: $cloud;
  border: none;
  margin-left: 10px;
  /* For search */
  /* margin-left: 10px; */
  font-size: 2vw;
  width: 15%;
}
.repo button:hover {
   background-color: rgb(80, 149, 90);
   transition: 0.3s;
   color: white;
}
