ul li {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0px;
  padding-bottom: 2;
  border-radius: 5px;
}
ul li .item {
  font-size: 16px;
  color: rgb(127, 183, 127);
  height: 34px;
}
ul li .item:hover {
  color: white;
}/*# sourceMappingURL=RepoResult.css.map */