.line-smal {
  width: 10%;
  height: 3px;
  background-color: #2a6574;
}

.line-big {
  width: 70%;
  height: 3px;
  background-color: #2a6574;
  margin-bottom: 200px;
}

.body-class-contact-form {
  background-color: rgb(42, 52, 117);
  text-align: center;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  min-height: 100vh;
  font-family: "Secular One", sans-serif;
}

.container {
  width: 35%;
  height: 50%;
  margin-top: 70px;
}

/* Form */
.form-container h1 {
  color: rgb(78, 156, 115);
  padding-bottom: 1rem;
  padding-top: 2rem;
}
.form-container p {
  color: rgb(78, 156, 115);
}

.form textarea, .form input {
  width: 450px;
  max-width: 90%;
  height: 60px;
  border-radius: 10px;
  background-color: rgb(78, 156, 115);
  border: none;
  color: white;
  padding-left: 10px;
}
.form textarea textarea, .form input textarea {
  height: 100px;
}
.form label {
  color: rgb(78, 156, 115);
}

form .btn {
  background-color: white;
  color: rgb(78, 156, 115);
}
form .btn:hover {
  background-color: rgb(78, 156, 115);
  color: white;
}

.submit-button {
  background-color: rgb(21, 97, 152);
  color: rgb(198, 191, 191);
  font-weight: 600;
  border-radius: 5px;
  border: none;
}

.submit-button:hover {
  background-color: blue;
  transition: 1s;
}

/* Form slut*/
/* Modal */
.modal {
  margin-top: 50px;
}

.modal-content {
  background-color: rgba(35, 32, 32, 0.952) !important;
}

.modal-body {
  font-size: 30px;
}

.modal-body p {
  color: rgb(71, 157, 117);
}

.modal-backdrop {
  display: none;
}/*# sourceMappingURL=ContactForm.css.map */