.container-nav {
  position: fixed;
  right: 10px;
  top: 350px;
  margin-top: 0;
  z-index: 1;
}
.container-nav .nav-bar ul {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 8px;
}
.container-nav .nav-bar .nav-items {
  display: flex;
  flex-direction: row;
  list-style: none;
  text-decoration: none;
  color: rgb(249, 252, 253);
  font-weight: 600;
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgb(127, 183, 127);
  cursor: pointer;
}
.container-nav .nav-bar .nav-items:hover {
  transition: 400ms;
  transform: scale(1.4);
}
.container-nav .nav-bar .nav-items::before {
  content: attr(data-section);
  position: absolute;
  right: 10px;
  transform: translateY(-25%);
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  color: rgb(224, 224, 224);
  padding: 2px 16px;
  opacity: 0;
}
.container-nav .nav-bar .nav-items:hover::before {
  opacity: 1;
}
.container-nav .nav-bar .nav-items.active {
  background-color: #2a6574;
}

.active-item {
  color: rgb(248, 242, 242);
  border-bottom: 0.1em rgb(252, 252, 255);
}

.sticky {
  position: fixed;
  top: 0;
}

@media screen and (max-width: 1400px) {
  .yellow .nav-items {
    font-size: 15px;
  }
  .yellow .nav-items-right {
    padding-left: 100px;
    font-size: 15px;
  }
}

@media screen and (max-width: 950px) {
  .nav-items,
  .nav-items-right {
    font-size: 15px;
  }
}
@media screen and (max-width: 900px) {
  .nav-items-right {
    padding-left: 0px;
  }
}
@media screen and (max-width: 770px) {
  .nav-items,
  .nav-items-right {
    font-size: 30px;
  }
  .container-nav {
    display: flex;
    justify-content: center;
    margin: 0;
  }
  nav ul {
    display: flex;
    flex-direction: column;
    gap: 0;
    text-align: center;
    padding-left: 0;
  }
  .contact-link {
    margin: 0;
  }
}
/* NAV SLUT*//*# sourceMappingURL=NavStyle.css.map */