@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");

$base-green: rgb(21, 141, 71);
$background: rgb(42, 52, 117);
// $background-dark: #212529;
$background-dark: #28285a;
// $cloud: rgb(78, 156, 115);
$cloud: rgb(127, 183, 127);
$line: #2a6574;

.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-90%);
  transition: all 1s;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

/* Full page */
.line-container {
  height: 50px;
}
.line-smal {
  width: 10%;
  height: 3px;
  background-color: $line;
}
.line-big {
  width: 70%;
  height: 3px;
  background-color: $line;
  // margin-top: 50px;
  margin-bottom: 500px;
}
.body-class-about {
  position: relative;
background: $background-dark;
background: linear-gradient(0deg, rgba(40,40,90,1) 68%, rgba(42,52,117,1) 99%);
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  // height: fit-content;
  font-family: "Secular One", sans-serif;
  padding-top: 150px;
  // width: 100%;
  min-height: 100vh;
  max-height: 4000%;
  overflow: visible; /* Ensure content is visible */
}
/* Full page slut*/
.row-custom {
  display: flex;
  gap: 5rem;
  max-width: 100%;
  padding-top: 50px;
}

/* Profile card */
.profile-card {
  text-align: center;
  flex-direction: column;
}
.profile-text-container {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: $background-dark;
  border-radius: 10px;
}

.profile-image img {
  border-radius: 10px;
  width: 30vw;
  height: 30vw;
  margin-top: 50px;
}

.title-color {
  color: rgb(0, 255, 132);
}

.social-media-about {
  justify-content: center;
  display: flex;
  gap: 20px;
  font-size: 20px;
}

.social-icons {
  transform: scale(1);
}
.profile-info {
  margin-top: 1rem;
}

.profile-info-details {
  display: flex;
  justify-content: center;
  gap: 20px;
  color: $cloud;
  font-weight: 600;
}

.send-mail button {
  width: 30%;
  background-color: rgb(21, 97, 152);
  color: rgb(198, 191, 191);
  font-weight: 600;
  border-radius: 5px;
  border: none;
}
.send-mail button:hover {
  background-color: blue;
  transition: 1s;
}
/* Profile card slut*/

/* ABOUT */

.about-info {
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.about-info-top {
  h3 {
    color: $cloud;
  }
  h1 {
    color: $cloud;
  }
}

.about-info-bottom {
  color: rgb(34, 149, 47);
  // margin-top: 10%;
  p {
    color: $cloud;
    font-size: 20px;
  }
}

.box-header {
  display: flex;
}

.box-content {
  display: flex;
  color: rgb(125, 157, 142);
  gap: 30px;
}

.box-content-fa {
  display: flex;
}
.language-icon {
  transform: scale(1.8);
}

.box {
  width: 100%;
  height: 80px;
  background-color: rgba(0, 128, 0, 0.217);
}

.box:hover {
  background-color: rgba(23, 197, 23, 0.217);
  transition: 1s;
}

.about-info-bottom-row {
  gap: 20px;
  display: flex;
  justify-content: space-evenly;
  justify-content: center;
}

.box-header-icon {
  transform: scale(1.8);
}
/* ABOUT slut*/

// @media screen and (max-width: 991px) {

// }

@media screen and (max-width: 784px) {
  .row-custom {
    gap: 0;
  }
}

@media screen and (max-width: 768px) {
  .row-custom {
    padding-top: 0px;
  }
  .profile-card,
  .about-info {
    display: block; // Make sure content is not
    opacity: 1;
    visibility: visible;
    transform: none;
  }
  .profile-image img {
    border-radius: 10px;
    // width: 60vw;
    // height: 60vw;
    // margin-top: 50px;
  }
  .about-info-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    h1 {
      text-align: center;
      font-size: 40px;
    }
    h3 {
      text-align: center;
      font-size: 20px;
    }
  }

  .about-info-bottom {
    p {
      text-align: center;
      font-size: 20px;
      // padding-left: 50px;
      // padding-right: 50px;
    }
  }
}
@media screen and (max-width: 640px) {
  .about-info-top {
    h1 {
      font-size: 30px;
    }
    h3 {
      font-size: 15px;
    }
  }
  .about-info-bottom {
    p {
      font-size: 15px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}

@media screen and (max-width: 576px) {

}
@media screen and (max-width: 480px) {
}
