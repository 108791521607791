.line-smal {
  width: 10%;
  height: 3px;
  background-color: #2a6574;
}

.body-class-dark {
  height: 100%;
  background-color: #28285a;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  min-height: 100vh;
  font-family: "Secular One", sans-serif;
  height: 100%;
}

.container-mywork {
  display: flex;
  flex-direction: column;
  gap: 100px;
  margin: 0;
  padding-top: 200px;
}

.header-mywork h1 {
  color: rgb(127, 183, 127);
}
.header-mywork p {
  color: rgb(127, 183, 127);
  font-size: 20px;
}

.content-mywork {
  border-radius: 10px;
  background-color: rgba(136, 181, 152, 0.129);
  padding-bottom: 50px;
}
.content-mywork h1 {
  color: rgb(125, 157, 142);
}
.content-mywork h2 {
  color: rgb(82, 155, 121);
}
.content-mywork .project-desc {
  padding-top: 60px;
  padding-left: 60px;
}
.content-mywork .project-desc p {
  color: rgb(127, 183, 127);
  font-size: 20px;
}
.content-mywork .dev-list .item {
  background-color: #212529;
  border-radius: 5px;
  font-size: 17px;
  color: rgb(127, 183, 127);
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.content-work-img {
  display: flex;
  justify-content: center;
}
.content-work-img img {
  filter: grayscale(20%) brightness(75%);
  border-radius: 10px;
  height: 30vh;
  cursor: zoom-in;
  padding: 0;
  margin-top: 20px;
}
@media screen and (max-width: 415px) {
  .content-work-img img {
    filter: grayscale(20%) brightness(75%);
    border-radius: 10px;
    max-width: 100%;
    height: 20vh;
    cursor: zoom-in;
    padding: 0;
    margin-top: 20px;
  }
}
@media screen and (max-width: 280px) {
  .content-work-img img {
    filter: grayscale(20%) brightness(75%);
    border-radius: 10px;
    max-width: 100%;
    height: 15vh;
    cursor: zoom-in;
    padding: 0;
    margin-top: 20px;
  }
}

.repo {
  font-family: "Staatliches", cursive;
  padding: 20px;
  border-radius: 10px;
}

.repo input {
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
}

.repo h1 {
  color: rgb(125, 157, 142);
}

.repo li {
  margin-top: 10px;
  padding-top: 10px;
  color: rgb(125, 157, 142);
  list-style: none;
}

.repo li a {
  padding-top: 10px;
  color: rgb(125, 157, 142);
  text-decoration: none;
  font-size: 20px;
}

.repo button {
  background-color: white;
  color: rgb(127, 183, 127);
  border: none;
  margin-left: 10px;
  /* For search */
  /* margin-left: 10px; */
  font-size: 2vw;
  width: 15%;
}

.repo button:hover {
  background-color: rgb(80, 149, 90);
  transition: 0.3s;
  color: white;
}/*# sourceMappingURL=MyProjects.css.map */